import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function OutdoorSpaces() {
  return (
    <Layout pageTitle="Outdoor Spaces">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Belfast Outdoor Spaces - USI Projects</title>
        <meta name="description" content="The Belfast Outdoor Spaces map is an interactive platform to make finding and booking a space for events and activities in Belfast easier."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-outdoors">

       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Belfast Outdoor Spaces</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading" >OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">
                  The Belfast Outdoor Spaces map is an interactive platform to make finding and booking 
                  a space for events and activities in Belfast easier.<br/><br/>
                  We’ve put together a list of outdoor spaces across the city that could be used for events, 
                  festivals, community gatherings etc. in response to demand from groups and organisations looking at safe Covid-response.<br/><br/>
                  This will immediately help event organisers with safe city spaces as the city re-opens, and longer-term to have a 
                  useful resource for events and festivals. 
                  </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p 
                  class="my-4 text-gray-500 leading-relaxed    mt-0">Design<br/>
                  Technology<br/>
                </p>
                <p class="text-2xl font-bold uppercase    mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed    mt-0">
                  Branding<br/>Web Design<br/>Web Development<br/>UX Design<br/>Mapping<br/></p>
                  <p class="text-2xl font-bold uppercase    mt-8">Visit</p>
                  <p class="my-4 text-gray-500 leading-relaxed mt-0"><a className='text-xs' target="_blank" href='https://www.belfastoutdoorspaces.com'>outdoorspaces.com</a>               </p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16  ">
          <img className="mx-auto" src="../img/projects/outdoor-steps.webp"/>
      </div>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16  ">
        <h3 class="my-4 text-black text-xl md:text-2xl lg:text-3xl  px-5 md:px-0 w-6/6 md:w-4/6">
        The Outdoor Spaces map allows users to search for outdoor spaces anywhere in Belfast, and filter results 
        by the typology of the space. Users can also suggest additional spaces to be added to the database. </h3>
        <img src="../img/projects/outdoors-graphics.webp"/>
      </div>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:py-16 md:my-16  ">
        <img src="../img/projects/tabletoutdoors.webp"/>
      </div>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-black">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../christmaslighting" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
